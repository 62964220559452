export const ToastMessages = {
  generic_error: "There was a problem with your request.",
  session: {
    expired: "Your session has expired, please login.",
  },
  user: {
    add: {
      success: "successfully",
    },
    delete: {
      success: "was deleted successfully.",
    },
    password: {
      change: {
        success: "Password Changed Successfully",
      },
      mismatch: {
        error: "Passwords do not match",
      },
    },
    profileUpdate: {
      success: "Profile Updated",
    },
    edit: {
      success: "successfully",
    },
  },
  orgs: {
    add: {
      success: "was added successfully.",
    },
    edit: {
      success: "edited successfully.",
    },
    delete: {
      error: "Unable to delete organiztaion.",
      success: "Organization deleted succesfully.",
    },
  },
  sensor: {
    add: {
      success: "Sensor added successfully.",
    },
    edit: {
      success: "Sensor edited successfully.",
    },
  },
};

export const Login = {
  no_access: {
    error: "Sorry, the account above does not have access.",
  },
};

export const Settings = {
  tabs: {
    profile: "Profile",
    password: "Password",
    orgManagement: "Org Management",
    userManagement: "User Management",
  },
};

export const OrgManagement = {
  title: "Manage Organization",
  addPartner: "+ Partner",
  addServiceProvider: "+ Service Provider",
};

export const ConfirmModalStrings = {
  title: "Are you sure you want to",
  confirm: "Yes",
  cancel: "Cancel",
  warning: "This action cannot be undone.",
};
export const KebabOptions = {
  viewDetails: {
    value: "viewDetails",
    label: "View Details",
  },
  editSensor: {
    value: "editSensor",
    label: "Edit Sensor Data",
  },
  export: {
    value: "export",
    label: "Export Daily Waterflow Data",
  },
};

export const TooltipsText = {
  waterPoint: {
    percentChange:
      "Difference between liters measured on the \
    most recent day compared to the rolling average",
    zeroLiterDays:
      "Number of days that have less than 100 liters in the last 30 days",
    lastUpdated: "Date the sensor data was last recorded",
  },
};

export const Passwords = {
  forgotPassword: {
    title: "Forgot Password?",
    subtitle:
      "Enter your email address and we'll send you a verification code.",
    button_send: "Send Verification Password",
    back: "Back",
  },
  requirements:
    "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long. No spaces allowed.",
  invalid: "The password does not meet the requirements.",
};

export const Comments = {
  placeholder: "Type Here...",
};

export const Filters = {
  keywordPlaceholder: "Search...",
};
