import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import router from "@/router";
import { showSessionExpired } from "./api";

const poolData = {
  UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.VUE_APP_COGNITO_AUTH_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

const generateCognitoUser = (userName, userPool) => {
  const cognitoUser = new CognitoUser({
    Username: userName,
    Pool: userPool,
  });
  return cognitoUser;
};

export const loginCognito = (
  userName,
  password,
  onLoginSuccess,
  onLoginError,
  onChangePassword,
) => {
  const cognitoUser = generateCognitoUser(userName, userPool);
  const authDetails = new AuthenticationDetails({
    Username: userName,
    Password: password,
  });
  authenticate(
    authDetails,
    cognitoUser,
    onLoginSuccess,
    onLoginError,
    onChangePassword,
  );
};

export const handleNewPassword = (newPassword, sessionAttributes) => {
  const cognitoUser = generateCognitoUser(sessionAttributes.username, userPool);
  cognitoUser.completeNewPasswordChallenge(newPassword, null, this);
};

function setLocalStorageTokens(idToken, refreshToken) {
  localStorage.setItem("idToken", idToken.jwtToken);
  localStorage.setItem("idTokenExpiry", idToken.payload.exp);
  localStorage.setItem("refreshToken", refreshToken.token);
  localStorage.setItem("isAuthenticated", true);
}

const authenticate = async (
  authDetails,
  cognitoUser,
  onLoginSuccess,
  onLoginError,
  onChangePassword,
) => {
await cognitoUser.authenticateUser(authDetails, {
    onSuccess: (data) => {
      setLocalStorageTokens(data.idToken, data.refreshToken);
      onLoginSuccess(data);
    },
    onFailure: (err) => {
      onLoginError(err);
    },
    newPasswordRequired: (userAttributes) => {
      onChangePassword(userAttributes, cognitoUser);
    },
  });
};

function onSignoutComplete() {
  router.push({ path: "/login" });
}

export const logoutCognito = () => {
  localStorage.clear();
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut(onSignoutComplete);
  } else {
    onSignoutComplete();
  }
};

export const changePassword = (oldPassword, newPassword, callback) => {
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser.getSession((err, session) => {
    if (session) {
      cognitoUser.changePassword(oldPassword, newPassword, callback);
    } else {
      return err;
    }
  });
};

export const forgotPassword = (email, onSuccess, onFailure) => {
  const cognitoUser = generateCognitoUser(email, userPool);
  cognitoUser.forgotPassword({
    onSuccess: function () {
      onSuccess();
    },
    onFailure: function (err) {
      onFailure(err);
    },
  });
};

export const confirmForgotPassword = (
  email,
  verificationCode,
  newPassword,
  onSuccess,
  onFailure,
) => {
  const cognitoUser = generateCognitoUser(email, userPool);
  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess: function () {
      onSuccess();
    },
    onFailure: function (err) {
      onFailure(err);
    },
  });
};

export const refreshCognitoSession = async () => {
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser.getSession((err, session) => {
    if (session) {
      const refresh_token = session.getRefreshToken();
      cognitoUser.refreshSession(refresh_token, (err, session) => {
        if (err) {
          // eslint-disable-next-line
          console.error(err);
          showSessionExpired();
        } else {
          setLocalStorageTokens(session.idToken, session.refreshToken);
        }
      });
    }
    if (err) {
      // eslint-disable-next-line
      console.error(err);
      showSessionExpired();
    }
  });
};
