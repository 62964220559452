import Vue from "vue";
import Vuex from "vuex";
import {
  addOrganization,
  createComment,
  createSensor,
  createUser,
  deleteComment,
  deleteOrganization,
  deleteUser,
  editComment,
  editOrganization,
  editSensor,
  getOrganizations,
  getRoles,
  getSensorById,
  getSensorComments,
  getSensorDailyActiveHoursById,
  getSensorDailyWaterFlowById,
  getSensorsHistorical,
  getUserById,
  getUsers,
  getWaterFlowForExport,
  getWaterPoints,
  updateUser,
} from "../api/api";
import {
  filterWaterPoints,
  removeEmptyValues,
  keywordSearch,
} from "../components/Shared/helpers";
import { ToastMessages } from "../components/Shared/strings";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    waterPoints: [],
    providers: [],
    organizations: [],
    waterPointsHistoricalFlag: [],
    detailWaterPoint: {},
    historicWaterFlow: [],
    filterProviders: [],
    additionalFilters: [],
    searchKeyword: "",
    filterOrganization: "",
    filterRole: "",
    filteredWaterPoints: [],
    filteredUsersByRole: [],
    filteredUsersByOrganization: [],

    users: [],
    roles: [],
    idToken: null,
    idTokenExpiry: null,
    refreshToken: null,
    isAuthenticated: false,
    loggedUser: {},
    resetPass: {},
    confirmResetPass: {},
    toastMessage: null,
    toastType: "error",
    loading: [],
  },
  mutations: {
    SET_WATERPOINTS(state, waterPoints) {
      state.waterPoints = waterPoints;
      state.waterPoints = waterPoints.map((wp) => ({
        ...wp,
        showInfoWindow: false,
      }));
    },
    SET_PROVIDERS(state, providers) {
      state.providers = providers;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_DETAIL_WATERPOINT(state, waterPoint) {
      state.detailWaterPoint = waterPoint;
    },
    UPDATE_DETAIL_WATERPOINT(state, response) {
      const comments = state.detailWaterPoint.comments;
      state.waterPoints.forEach((element) => {
        if (parseInt(element.id) === parseInt(response.id)) {
          state.detailWaterPoint = {
            // prevent chart data points from being over-written
            dailyWaterFlow: state.detailWaterPoint.dailyWaterFlow,
            dailyActiveHours: state.detailWaterPoint.dailyAvtiveHours,
            ...element,
            ...response,
          };
        }
      });
      state.detailWaterPoint = { ...state.detailWaterPoint, comments };
    },
    ADD_DAILY_WATER_FLOW_PROPS(state, response) {
      const dailyWaterFlow = [...response];
      state.detailWaterPoint = { ...state.detailWaterPoint, dailyWaterFlow };
    },
    ADD_DAILY_ACTIVE_HOURS_PROPS(state, response) {
      const dailyActiveHours = [...response];
      state.detailWaterPoint = { ...state.detailWaterPoint, dailyActiveHours };
    },
    ADD_USER(state, user) {
      state.users.push(user);
      // This should be updated to interact with BE and real data
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations;
    },
    SET_HISTORICAL_STATUS(state, response) {
      state.waterPointsHistoricalFlag = response;
    },
    RESET_HISTORICAL_STATUS(state) {
      state.waterPointsHistoricalFlag = [];
    },
    FILTER_WATERPOINTS(state, filter) {
      if (filter?.filterProviders) {
        if (filter.filterProviders !== "all") {
          state.filterProviders = filter.filterProviders;
        } else {
          state.filterProviders = [];
        }
      } else if (filter?.additionalFilters) {
        state.additionalFilters = filter.additionalFilters;
      }

      state.filteredWaterPoints = filterWaterPoints(
        state.waterPoints,
        state.filterProviders,
        state.additionalFilters,
        state.searchKeyword,
      );
    },
    KEYWORD_SEARCH(state, keyword) {
      state.searchKeyword = keyword;
      state.filteredWaterPoints = filterWaterPoints(
        state.waterPoints,
        state.filterProviders,
        state.additionalFilters,
        keyword,
      );
    },
    FILTER_USERS_BY_ROLE(state, filter) {
      state.filterRole = filter;
      if (filter === "all") {
        state.filteredUsersByRole = state.users;
      } else {
        state.filteredUsersByRole = state.users.filter(
          (elem) => elem.roleId === filter,
        );
      }
    },
    FILTER_USERS_BY_ORGANIZATION(state, filter) {
      state.filterOrganization = filter;
      if (filter === "all") {
        state.filteredUsersByOrganization = state.filteredUsersByRole;
      } else {
        state.filteredUsersByOrganization = state.filteredUsersByRole.filter(
          (elem) => elem.organizationName === filter,
        );
      }
    },
    RESET_FILTER_ORGANIZATION(state) {
      state.filterOrganization = "";
    },
    RESET_FILTER_ROLE(state) {
      state.filterRole = "";
    },
    RESET_FILTER_PROVIDERS(state) {
      state.filterProviders = [];
    },
    RESET_FILTER_ADDITIONAL(state) {
      state.additionalFilters = [];
    },
    ADD_SENSOR(state, sensor) {
      state.waterPoints = [...state.waterPoints, sensor];
    },
    EDIT_SENSOR(state, sensor) {
      for (let i = 0; i < state.waterPoints.length; i++) {
        if (state.waterPoints[i].id === sensor.id) {
          state.waterPoints[i] = { ...state.waterPoints[i], ...sensor };
          break;
        }
      }
    },
    DELETE_USER(state, user) {
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].id === user.id) {
          state.users.splice(i, 1);
          break;
        }
      }
    },
    EDIT_USER(state, updatedUser) {
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].id === updatedUser.id) {
          state.users.splice(i, 1, updatedUser);
          break;
        }
      }
    },
    BULK_UPLOAD_SENSORS(state, sensors) {
      sensors.forEach((elem) => {
        if (elem.sensorID) {
          state.waterPoints.push(elem);
          state.providers.push(elem.serviceProvider);
        }
      });
    },
    SET_ID(state, idToken) {
      state.idToken = idToken;
      localStorage.setItem("idToken", idToken);
    },
    SET_ID_EXPIRY(state, expiry) {
      state.idTokenExpiry = expiry;
      localStorage.setItem("idTokenExpiry", expiry);
    },
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
      localStorage.setItem("isAuthenticated", isAuthenticated);
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken;
      localStorage.setItem("refreshToken", refreshToken);
    },
    GET_LOGGED_USER_DATA(state, loggedUser) {
      state.loggedUser = loggedUser;
    },
    SET_USER_DATA(state, user) {
      state.loggedUser = user;
      localStorage.setItem("loggedUser", JSON.stringify(state.loggedUser));
    },
    SET_PARTNER_SELECTED(state, partner) {
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      loggedUser = { ...loggedUser, selectedOrg: partner };
      state.loggedUser = loggedUser;
      localStorage.setItem("loggedUser", JSON.stringify(state.loggedUser));
    },
    TOGGLE_SHOW_INFO_WINDOW(state, marker) {
      for (let i = 0; i < state.filteredWaterPoints.length; ++i) {
        if (state.filteredWaterPoints[i].id === marker.id) {
          state.filteredWaterPoints[i].showInfoWindow =
            !state.filteredWaterPoints[i].showInfoWindow;
        } else {
          state.filteredWaterPoints[i].showInfoWindow = false;
        }
      }
    },
    SET_RESET_PASSWORD(state, response) {
      state.resetPass = response;
    },
    SET_CONFIRM_RESET_PASSWORD(state, response) {
      state.confirmResetPass = response;
    },
    ADD_PARTNER(state, data) {
      const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      loggedUser.organizations.push({
        id: data.id,
        name: data.name,
        numUsers: 0,
        serviceProviders: [],
        type: "Partner",
      });
      localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
      state.loggedUser = loggedUser;
    },
    ADD_SERVICE_PROVIDER(state, response) {
      const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      const selectedOrg = loggedUser.selectedOrg;
      if (selectedOrg.type === "Partner") {
        selectedOrg.serviceProviders.push({
          id: response.id,
          name: response.name,
          numUsers: 0,
          type: "ServiceProvider",
        });
        for (let i = 0; i < loggedUser.organizations.length; ++i) {
          if (loggedUser.organizations[i].id === response.parentId) {
            loggedUser.organizations[i].serviceProviders.push({
              id: response.id,
              name: response.name,
              numUsers: 0,
              type: "ServiceProvider",
            });
          }
        }
        localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
        state.loggedUser = loggedUser;
      }
    },
    DELETE_SERVICE_PROVIDER(state, id) {
      const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      if (loggedUser.selectedOrg.type === "Partner") {
        loggedUser.selectedOrg.serviceProviders =
          loggedUser.selectedOrg.serviceProviders.filter(
            (serviceProvider) => serviceProvider.id !== id,
          );
        loggedUser.organizations.forEach((organization) => {
          if (organization.id === loggedUser.selectedOrg.id) {
            organization.serviceProviders =
              organization.serviceProviders.filter(
                (serviceProvider) => serviceProvider.id !== id,
              );
          }
        });
        localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
        state.loggedUser = loggedUser;
      }
    },
    EDIT_SERVICE_PROVIDER(state, data) {
      const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      // only super admin (associated to partner) can edit service provider
      if (
        data.type === "ServiceProvider" &&
        loggedUser.selectedOrg.type === "Partner"
      ) {
        const serviceProviderIndex =
          loggedUser.selectedOrg.serviceProviders.findIndex(
            (serviceProvider) => serviceProvider.id === data.id,
          );
        if (serviceProviderIndex !== -1) {
          loggedUser.selectedOrg.serviceProviders[serviceProviderIndex].name =
            data.name;

          loggedUser.organizations.forEach((organization) => {
            if (organization.serviceProviders) {
              const orgServiceProviderIndex =
                organization.serviceProviders.findIndex(
                  (serviceProvider) => serviceProvider.id === data.id,
                );
              if (orgServiceProviderIndex !== -1) {
                organization.serviceProviders[orgServiceProviderIndex].name =
                  data.name;
              }
            }
          });
        }
      } else {
        for (let i = 0; i < loggedUser.organizations.length; ++i) {
          if (loggedUser.organizations[i].id === data.id) {
            loggedUser.organizations[i].name = data.name;
            loggedUser.selectedOrg.name = data.name;
          }
        }
      }
      localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
      state.loggedUser = loggedUser;
    },
    UPDATE_CURRENT_USER(state, response) {
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      loggedUser = { ...loggedUser, ...response };
      state.loggedUser = loggedUser;
      localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
    },
    RESET_WATERPOINTS(state) {
      state.waterPoints = [];
      state.filteredWaterPoints = [];
    },
    SET_TOAST_MESSAGE(state, data) {
      state.toastMessage = { message: data.message, type: data.type };
    },
    SET_WATER_FLOW_HISTORIC(state, historic) {
      state.historicWaterFlow = historic;
    },
    ADD_LOADING(state, module_name) {
      // eslint-disable-next-line
      console.log(`loading ${module_name}`);
      state.loading.push(module_name);
    },
    REMOVE_LOADING(state, module_name) {
      const index = state.loading.indexOf(module_name);
      // eslint-disable-next-line
      // only remove when name found in array
      if (index > -1) {
        // only remove one instance
        // eslint-disable-next-line
        console.log(`unloading ${module_name}`);
        state.loading.splice(index, 1);
      }
    },
    SET_TEMP_PASSWORD(state, password) {
      state.tempPassword = password;
    },
    CLEAR_TEMP_PASSWORD(state) {
      delete state.tempPassword;
    },
    GET_SENSOR_COMMENTS(state, comments) {
      state.detailWaterPoint = { ...state.detailWaterPoint, ...comments };
    },
    ADD_COMMENT(state, comment) {
      let comments = state.detailWaterPoint.comments;
      comments.unshift(comment);
      state.detailWaterPoint.comments = comments;
    },
    EDIT_COMMENT(state, comment) {
      const comments = state.detailWaterPoint.comments;
      const commentIndex = comments.findIndex(
        (element) => element.id === comment.id,
      );
      comments[commentIndex] = comment;
      state.detailWaterPoint.comments = comments;
    },
    DELETE_COMMENT(state, comment) {
      const comments = state.detailWaterPoint.comments;
      const commentIndex = comments.findIndex(
        (element) => element.id === comment.id,
      );
      if (commentIndex > -1) {
        comments.splice(commentIndex, 1);
      }
      state.detailWaterPoint.comments = comments;
    },
  },
  actions: {
    startLoading({ commit }, module_name) {
      commit("ADD_LOADING", module_name);
    },
    endLoading({ commit }, module_name) {
      commit("REMOVE_LOADING", module_name);
    },
    async fetchWaterPoints({ commit }) {
      commit("ADD_LOADING", "fetchWaterPoints");
      try {
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        const response = await getWaterPoints(loggedUser.selectedOrg.id);
        commit("SET_WATERPOINTS", response);
      } finally {
        // eslint-disable-next-line
        // remove name from loading array even if API errored
        commit("REMOVE_LOADING", "fetchWaterPoints");
      }
    },
    async toggleShowInfoWindow({ commit }, marker) {
      commit("TOGGLE_SHOW_INFO_WINDOW", marker);
    },
    async fetchHistoricalFlagStatus({ commit }) {
      commit("ADD_LOADING", "fetchHistoricalFlagStatus");
      try {
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        const selectedOrg = loggedUser.selectedOrg;
        let selectedServiceProviderIds = [];
        if (selectedOrg.type === "Partner") {
          selectedServiceProviderIds = this.state.filterProviders.map(
            (name) => {
              // send "null" as a string for undefined service providers
              if (!name) {
                return "null";
              }
              // look up service provider ID from local storage
              const nameIdObj = selectedOrg.serviceProviders.find(
                (obj) => obj.name === name,
              );
              return nameIdObj.id;
            },
          );
        }
        const response = await getSensorsHistorical(
          loggedUser.selectedOrg.id,
          selectedServiceProviderIds,
        );
        commit("SET_HISTORICAL_STATUS", response);
      } finally {
        commit("REMOVE_LOADING", "fetchHistoricalFlagStatus");
      }
    },
    fetchProviders({ commit }) {
      const providersSet = new Set();
      this.state.waterPoints.forEach((item) => {
        providersSet.add(item.serviceProvider);
      });
      const sortedProvidersArray = Array.from(providersSet).sort();
      commit("SET_PROVIDERS", sortedProvidersArray);
    },
    async fetchUsers({ commit }) {
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      const response = await getUsers(loggedUser.selectedOrg.id);
      commit("SET_USERS", response);
    },
    async fetchRoles({ commit, state }) {
      const roles = state.roles.length > 0 ? state.roles : await getRoles();
      commit("SET_ROLES", roles);
    },
    async fetchOrganizations({ commit, state }) {
      const response =
        state.organizations.length > 0
          ? state.organizations
          : await getOrganizations();
      commit("SET_ORGANIZATIONS", response);
    },
    setDetailWaterPoint({ commit }, waterPoint) {
      commit("SET_DETAIL_WATERPOINT", waterPoint);
    },
    async updateDetailWaterPoint({ commit }, params) {
      const response = await getSensorById(params.id);
      commit("UPDATE_DETAIL_WATERPOINT", response);
    },
    async getSensorDailyWaterFlow({ commit }, params) {
      commit("ADD_LOADING", "getSensorDailyWaterFlow");
      try {
        const response = await getSensorDailyWaterFlowById(
          params.id,
          params.monthlyTimeframe,
        );
        commit("ADD_DAILY_WATER_FLOW_PROPS", response);
      } finally {
        commit("REMOVE_LOADING", "getSensorDailyWaterFlow");
      }
    },
    async getSensorDailyActiveHours({ commit }, params) {
      commit("ADD_LOADING", "getSensorDailyActiveHours");
      try {
        const response = await getSensorDailyActiveHoursById(params.id);
        commit("ADD_DAILY_ACTIVE_HOURS_PROPS", response);
      } finally {
        commit("REMOVE_LOADING", "getSensorDailyActiveHours");
      }
    },
    async getSensorComments({ commit }, params) {
      commit("ADD_LOADING", "getSensorComments");
      try {
        const response = await getSensorComments(params.id);
        commit("GET_SENSOR_COMMENTS", response);
      } finally {
        commit("REMOVE_LOADING", "getSensorComments");
      }
    },
    async addComment({ commit }, data) {
      commit("ADD_LOADING", "addComment");
      try {
        const response = await createComment(data.id, {
          comment: data.comment,
        });
        commit("ADD_COMMENT", response);
      } finally {
        commit("REMOVE_LOADING", "addComment");
      }
    },
    async editComment({ commit }, data) {
      commit("ADD_LOADING", "editComment");
      try {
        const response = await editComment(data.sensorId, data.commentId, {
          comment: data.comment,
        });
        commit("EDIT_COMMENT", response);
      } finally {
        commit("REMOVE_LOADING", "editComment");
      }
    },
    async deleteComment({ commit }, data) {
      commit("ADD_LOADING", "deleteComment");
      try {
        const response = await deleteComment(data.sensorId, data.commentId);
        commit("DELETE_COMMENT", response);
      } finally {
        commit("REMOVE_LOADING", "deleteComment");
      }
    },
    async addUser({ commit }, user) {
      delete user.id;
      const createdUser = await createUser(user);
      commit("ADD_USER", createdUser);
    },
    async deleteUser({ commit }, user) {
      commit("ADD_LOADING", "deleteUser");
      try {
        await deleteUser(user.id);
        this.dispatch("showToast", {
          message: `${user.firstName} ${user.lastName} ${ToastMessages.user.delete.success}`,
          type: "success",
        });
        commit("DELETE_USER", user);
      } catch {
        this.dispatch("showToast", {
          message: ToastMessages.generic_error,
          type: "error",
        });
      } finally {
        commit("REMOVE_LOADING", "deleteUser");
      }
    },
    async editUser({ commit }, user) {
      const userId = user.id;
      delete user.id;
      await updateUser(userId, user);
      user.id = userId;
      commit("EDIT_USER", user);
    },
    async updateCurrentUser({ commit }) {
      let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
      const response = await getUserById(loggedUser.id);
      commit("UPDATE_CURRENT_USER", response);
    },
    filterWaterPoints({ commit }, filter) {
      commit("FILTER_WATERPOINTS", filter);
    },
    searchWaterPoints({ commit }, keyword) {
      commit("KEYWORD_SEARCH", keyword);
    },
    filterUsersByRole({ commit }, filter) {
      commit("FILTER_USERS_BY_ROLE", filter);
    },
    filterUsersByOrganization({ commit }, filter) {
      commit("FILTER_USERS_BY_ORGANIZATION", filter);
    },
    resetFilterOrganization({ commit }) {
      commit("RESET_FILTER_ORGANIZATION");
    },
    resetWaterPoints({ commit }) {
      commit("RESET_WATERPOINTS");
    },
    resetHistoricalStatus({ commit }) {
      commit("RESET_HISTORICAL_STATUS");
    },
    resetFilterProviders({ commit }) {
      commit("RESET_FILTER_PROVIDERS");
    },
    async addSensor({ commit }, sensor) {
      const reducedSensor = removeEmptyValues(sensor);
      await createSensor(reducedSensor).then((data) => {
        data.lastRecorded = null;
        commit("ADD_SENSOR", data);
      });

      commit("FILTER_WATERPOINTS");
    },
    async editSensor({ commit }, sensor) {
      // id, imei, sensorId, model, partner, redFlagLastChangedDate, and serviceProvider are all items that cannot be changed
      const id = sensor.id;
      delete sensor.sensorId;
      delete sensor.serviceProvider;
      delete sensor.partner;
      delete sensor.id;
      delete sensor.imei;
      delete sensor.model;
      delete sensor.redFlagLastChangedDate;
      const reducedSensor = removeEmptyValues(sensor);
      await editSensor(reducedSensor, id).then((data) => {
        commit("EDIT_SENSOR", data);
      });

      commit("FILTER_WATERPOINTS");
    },
    bulkUploadSensors({ commit }, sensors) {
      commit("BULK_UPLOAD_SENSORS", sensors);
    },
    resetAllFilters({ commit }) {
      commit("RESET_FILTER_ORGANIZATION");
      commit("RESET_FILTER_ROLE");
      commit("RESET_FILTER_PROVIDERS");
      commit("RESET_FILTER_ADDITIONAL");
    },
    async addOrganizationAction({ commit }, org) {
      const response = await addOrganization(org.name, org.type, org.parentId);
      if (org.type !== "Partner") {
        commit("ADD_SERVICE_PROVIDER", response);
      } else {
        commit("ADD_PARTNER", response);
      }
    },
    async deleteOrganizationAction({ commit }, id) {
      await deleteOrganization(id);
      commit("DELETE_SERVICE_PROVIDER", id);
    },
    async editOrganizationAction({ commit }, data) {
      await editOrganization(data);
      commit("EDIT_SERVICE_PROVIDER", data);
    },
    async getLoggedUserData({ commit, state }) {
      const loggedUser =
        state.loggedUser || JSON.parse(localStorage.getItem("loggedUser"));
      commit("GET_LOGGED_USER_DATA", loggedUser);
    },
    setPartnerSelected({ commit }, partner) {
      commit("SET_PARTNER_SELECTED", partner);
    },
    async exportWaterFlow({ commit }, data) {
      commit("ADD_LOADING", "exportWaterFlow");
      try {
        const response = await getWaterFlowForExport(data);
        commit("SET_WATER_FLOW_HISTORIC", response);
      } finally {
        commit("REMOVE_LOADING", "exportWaterFlow");
      }
    },
    showToast({ commit }, data) {
      commit("SET_TOAST_MESSAGE", data);
    },
    async setLoggedUser({ commit }, payload) {
      commit("SET_USER_DATA", payload.user);
      commit("SET_ID", payload.token);
      commit("SET_IS_AUTHENTICATED", true);
    },
    setTempPassword({ commit }, password) {
      commit("SET_TEMP_PASSWORD", password);
    },
    clearTempPassword({ commit }) {
      commit("CLEAR_TEMP_PASSWORD");
    },
  },
  modules: {},
  getters: {
    isAuthenticated: (state) =>
      state.isAuthenticated || localStorage.getItem("isAuthenticated"),
    roleLoggedUser: (state) =>
      state.loggedUser.roleName ||
      JSON.parse(localStorage.getItem("loggedUser")).roleName,
    getProviders: (state) => state.providers,
    getSelectedOrg: (state) => state.loggedUser.selectedOrg,
    getComments: (state) => state.detailWaterPoint.comments,
    getAdditionalFilters: (state) => state.additionalFilters,
  },
});
