import store from "@/store";
import axios from "axios";
import { refreshCognitoSession, logoutCognito } from "./cognito";
import {
  isTokenExpired,
  tokenIsRefreshing,
  tokenRefreshingComplete,
} from "../components/Shared/helpers";
import { ToastMessages } from "../components/Shared/strings";

const getIdToken = async () => {
  /** Token is missing - logout */
  if (!localStorage.getItem("idToken")) {
    showSessionExpired();
  }
  /** Token is refreshing...please wait  */
  if (tokenIsRefreshing()) {
    /** Prevent user from being stuck in token refresh */
    const timeOut = setTimeout(() => {
      showSessionExpired();
    }, 10000);
    await tokenRefreshingComplete();
    clearTimeout(timeOut);
    return localStorage.getItem("idToken");
  }

  /** ID Token is expired - refresh it */
  if (isTokenExpired(localStorage.getItem("idTokenExpiry"))) {
    localStorage.setItem("idToken", "refreshing");
    refreshCognitoSession();
    await tokenRefreshingComplete();
    return localStorage.getItem("idToken");
  } else {
    /** Token is valid in its current state so return it */
    return localStorage.getItem("idToken");
  }
};

export function showSessionExpired() {
  store.dispatch("showToast", {
    message: ToastMessages.session.expired,
    type: "error",
  });
  logoutCognito();
  setTimeout(() => {
    this.$router.push("/login");
  }, 2000);
}

export const getUsers = async (selectedOrgId) => {
  const idToken = await getIdToken();
  const users = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API +
      `users?partnerId=${selectedOrgId}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return users.data.users;
};

export const createUser = async (user) => {
  const idToken = await getIdToken();
  const response = await axios.post(
    process.env.VUE_APP_COGNITO_CONTENT_API + "users",
    user,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const deleteUser = async (userId) => {
  const idToken = await getIdToken();
  const response = await axios.delete(
    `${process.env.VUE_APP_COGNITO_CONTENT_API}users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const updateUser = async (userId, user) => {
  const idToken = await getIdToken();
  const response = await axios.put(
    `${process.env.VUE_APP_COGNITO_CONTENT_API}users/${userId}`,
    user,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const updateUserById = async (data) => {
  const idToken = await getIdToken();
  const id = data.id;
  delete data.id;
  const response = await axios.put(
    process.env.VUE_APP_COGNITO_CONTENT_API + `users/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const getRoles = async () => {
  const idToken = await getIdToken();
  const response = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API + "roles",
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data.roles;
};

export const getOrganizations = async () => {
  const idToken = await getIdToken();
  const response = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API + "organizations",
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data.organizations;
};

export const getWaterPoints = async (selectedOrgId) => {
  const idToken = await getIdToken();
  const sensors = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API +
      `sensors?partnerId=${selectedOrgId}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return sensors.data.sensors;
};

export const createSensor = async (sensor) => {
  const idToken = await getIdToken();
  const response = await axios.post(
    process.env.VUE_APP_COGNITO_CONTENT_API + "sensors",
    sensor,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const editSensor = async (sensor, sensorId) => {
  const idToken = await getIdToken();
  const response = await axios.put(
    process.env.VUE_APP_COGNITO_CONTENT_API + `sensors/${sensorId}`,
    sensor,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const getSensorById = async (sensorId) => {
  const idToken = await getIdToken();
  const sensor = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API + `sensors/${sensorId}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return sensor.data;
};

export const getSensorDailyWaterFlowById = async (
  sensorId,
  monthlyTimeframe,
) => {
  const idToken = await getIdToken();
  const sensor = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API +
      `sensors/${sensorId}/daily-water-flow?monthlyTimeframe=${
        monthlyTimeframe || 3
      }`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return sensor.data.data;
};

export const getSensorDailyActiveHoursById = async (sensorId) => {
  const idToken = await getIdToken();
  const sensor = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API +
      `sensors/${sensorId}/daily-active-hours`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return sensor.data.data;
};

export const getSensorComments = async (sensorId) => {
  const idToken = await getIdToken();
  const sensorComments = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API + `sensors/${sensorId}/comments`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return sensorComments.data;
};

export const createComment = async (sensorId, comment) => {
  const idToken = await getIdToken();
  const response = await axios.post(
    process.env.VUE_APP_COGNITO_CONTENT_API + `sensors/${sensorId.id}/comments`,
    comment,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const editComment = async (sensorId, commentId, comment) => {
  const idToken = await getIdToken();
  const response = await axios.put(
    process.env.VUE_APP_COGNITO_CONTENT_API +
      `sensors/${sensorId.id}/comments/${commentId}`,
    comment,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const deleteComment = async (sensorId, commentId) => {
  const idToken = await getIdToken();
  const response = await axios.delete(
    process.env.VUE_APP_COGNITO_CONTENT_API +
      `sensors/${sensorId}/comments/${commentId}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const getSensorsHistorical = async (
  selectedOrgId,
  serviceProviderIds,
) => {
  const idToken = await getIdToken();
  let param = `selectedOrgId=${selectedOrgId}`;
  if (serviceProviderIds.length > 0) {
    param = param.concat(`&serviceProviderIds=${serviceProviderIds}`);
  }
  const sensors = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API +
      `sensors/historical-flag-status?${param}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return sensors.data.data;
};

export const addOrganization = async (orgName, type, parentId = null) => {
  const idToken = await getIdToken();
  const payload = parentId
    ? { name: orgName, parentId: parentId }
    : { name: orgName };
  const response = await axios.post(
    `${process.env.VUE_APP_COGNITO_CONTENT_API}organizations?orgType=${type}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const deleteOrganization = async (idOrg) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.delete(
      `${process.env.VUE_APP_COGNITO_CONTENT_API}organizations/${idOrg}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      },
    );
    store.dispatch("showToast", {
      message: ToastMessages.orgs.delete.success,
      type: "success",
    });
    return response.data;
  } catch (error) {
    store.dispatch("showToast", {
      message: ToastMessages.orgs.delete.error,
      type: "error",
    });
    throw error;
  }
};

export const editOrganization = async (data) => {
  const idToken = await getIdToken();
  const response = await axios.put(
    `${process.env.VUE_APP_COGNITO_CONTENT_API}organizations/${data.id}`,
    { name: data.name },
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const getUserById = async (id) => {
  const idToken = await getIdToken();
  const response = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API + `users/${id}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};

export const getWaterFlowForExport = async (data) => {
  const idToken = await getIdToken();
  const response = await axios.get(
    process.env.VUE_APP_COGNITO_CONTENT_API +
      `sensors/${data.id}/export-data?interval=${data.interval}&monthlyTimeframe=${data.monthlyTimeframe}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    },
  );
  return response.data;
};
