import { getOrganizations } from "../../api/api";

export const filterWaterPoints = (
  waterPoints,
  providerFilters,
  additionalFilters,
  keyword,
) => {
  let filteredWaterPoints = orderByStatus(waterPoints);
  if (providerFilters.length) {
    filteredWaterPoints = waterPoints.filter((waterPoint) => {
      const matchesFilters = providerFilters.includes(
        waterPoint.serviceProvider,
      );
      return matchesFilters;
    });
  }
  if (!additionalFilters.length) {
    return keywordSearch(filteredWaterPoints, keyword);
  }

  let result = [];
  for (let j = 0; j < filteredWaterPoints.length; ++j) {
    for (let i = 0; i < additionalFilters.length; ++i) {
      const property = Object.keys(additionalFilters[i])[0];
      if (
        filteredWaterPoints[j][property] === additionalFilters[i][property] &&
        !checkIfWaterPointExists(result, filteredWaterPoints[j])
      ) {
        result.push(filteredWaterPoints[j]);
      }
    }
  }
  return keywordSearch(result, keyword);
};

const checkIfWaterPointExists = (result, point) => {
  return result.find((r) => point.id === r.id);
};

export const orderByStatus = (waterPoints) => {
  const order = { Red: 0, Yellow: 1, Green: 2, Grey: 3 };
  const sortedArray = waterPoints.sort((a, b) => {
    const statusA = a.status;
    const statusB = b.status;
    const valueA = order[statusA] !== undefined ? order[statusA] : Infinity;
    const valueB = order[statusB] !== undefined ? order[statusB] : Infinity;
    return valueA - valueB;
  });
  return sortedArray;
};

export const removeEmptyValues = (object) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    if (value !== "" && value !== "None") {
      acc[key] = value;
    }
    return acc;
  }, {});

export const generateUserObject = async (data) => {
  const user = data;
  const orgs = await getOrganizations();
  user.organizations = orgs;
  user.selectedOrg = orgs[0];
  return user;
};

export const tokenIsRefreshing = () => {
  if (localStorage.getItem("idToken") === "refreshing") {
    return true;
  } else {
    return false;
  }
};

export const tokenRefreshingComplete = async () => {
  return await new Promise((resolve) => {
    const interval = setInterval(() => {
      if (localStorage.getItem("idToken") !== "refreshing") {
        clearInterval(interval);
        resolve();
      }
    }, 200);
  });
};

export const normalizeString = (string) => {
  const removeSpaces = string.replace(/\s/g, "");
  return removeSpaces.toLowerCase();
};

export const getTopLeftPosition = (element) => {
  const rect = element.getBoundingClientRect();
  const x = rect.left <= window.innerWidth / 2 ? "left" : "right";
  const y = rect.top <= window.innerHeight / 2 ? "top" : "bottom";
  return { x, y };
};

export const isTokenExpired = (tokenExpiry) => {
  const expiryTime =
    // 10 digit value is in seconds - needs to conver to ms for comparison
    tokenExpiry.length <= 10 ? tokenExpiry * 1000 : tokenExpiry;
  const currentTime = Date.now();
  return expiryTime <= currentTime;
};

export const keywordSearch = (waterPoints = [], keyword = "") => {
  if (keyword === "") {
    return waterPoints;
  }
  const reg = new RegExp(keyword, "i");

  return waterPoints.filter((item) => {
    let flag = false;
    Object.values(item).forEach((prop) => {
      if (reg.test(prop)) {
        flag = true;
      }
    });

    return flag;
  });
};
