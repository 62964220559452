import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: store.getters.isAuthenticated ? "/dashboard" : "/login",
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPageComponent.vue"),
  },
  // Not part of MVP so commenting out for new
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "register" */ "../views/RegisterPageComponent.vue"
  //     ),
  // },
  {
    path: "/updatePassword",
    name: "UpdatePassword",
    component: () =>
      import(
        /* webpackChunkName: "update" */ "../views/ChangePasswordComponent.vue"
      ),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "../views/DashboardPageComponent.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/:id",
    name: "Detail",
    redirect: "/dashboard/:id/daily-water-flow",
    component: () =>
      import(
        /* webpackChunkName: "Detail" */ "../views/DetailPageComponent.vue"
      ),
    children: [
      {
        path: "daily-water-flow",
        name: "Daily Water Flow",
        component: () => import("../components/Details/DailyWaterFlow.vue"),
      },
      {
        path: "daily-active-hours",
        name: "Daily Active Hours",
        component: () => import("../components/Details/DailyActiveHours.vue"),
      },
      {
        path: "map",
        name: "Map",
        component: () => import("../components/Details/DetailsMap.vue"),
      },
      {
        path: "comments",
        name: "Comments",
        component: () => import("../components/Details/DetailsComments.vue"),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    redirect: "/settings/profile",
    component: () =>
      import(
        /* webpackChunkName: "Settings" */ "../views/SettingsPageComponent.vue"
      ),
    children: [
      {
        path: "profile",
        name: "Profile",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../components/Settings/Profile.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "password",
        name: "Password",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../components/Settings/Password.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "organization",
        name: "Org Management",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../components/Settings/OrgManagement.vue"
          ),
        meta: {
          roles: ["Admin", "SuperAdmin"],
          requiresAuth: true,
        },
      },
      {
        path: "users",
        name: "User Management",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../components/Settings/UserManagement.vue"
          ),
        meta: {
          roles: ["Admin", "SuperAdmin"],
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthenticated = store?.getters.isAuthenticated || null;
    if (!isAuthenticated) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.roles)) {
    const role = store?.getters.roleLoggedUser || null;
    const roles = to.matched[to.matched.length - 1].meta.roles;
    if (roles.includes(role)) {
      next();
    } else {
      next({
        path: "/settings/profile",
      });
    }
    next();
  }
});

export default router;
