<template>
  <div></div>
</template>

<script>
const options = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};
export default {
  name: "Toast",
  data() {
    return {
      toastMessage: "",
      toastType: "",
    };
  },
  created() {
    this.toastMessage = this.$store.state.toastMessage;
    this.toastType = this.$store.state.toastType;
    this.$store.watch(
      (state) => state.toastMessage,
      (message) => {
        this.toastMessage = message;
        if (message) {
          this.showToast(message);
        }
      },
      (state) => state.toastType,
      (type) => {
        this.toastType = type;
      },
    );
  },

  methods: {
    showToast(message) {
      switch (message.type) {
        case "success":
          this.$toast.success(message.message, options);
          return;
        default:
          this.$toast.error(message.message, options);
      }
    },
  },
};
</script>
