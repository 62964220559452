import Vue from "vue";
import * as VueGoogleMaps from "vue3-google-maps";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toastification from "vue-toastification";
import Toast from "@/components/Shared/Toast.vue";
import "vue-toastification/dist/index.css";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

// Vue.use(VueTelInput);

Vue.use(VueMaterial);

Vue.config.productionTip = false;

Vue.use(FloatingVue, {
  themes: {
    "info-tooltip": {
      $extend: "tooltip",
      $resetCss: true,
      preventOverflow: true,
      autoSize: true,
    },
  },
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places",
  },
});
Vue.use(Toastification);

Vue.component("Toast", Toast);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
