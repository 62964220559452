<template>
  <div id="app" :class="{ 'no-navbar': noNavbar }">
    <router-view class="page" />
    <div v-if="loading" class="loading-spinner-wrapper">
      <LoadingSpinner class="loading-spinner"></LoadingSpinner>
    </div>
    <Toast />
  </div>
</template>
<script>
import Toast from "@/components/Shared/Toast.vue";
import LoadingSpinner from "./components/Shared/LoadingSpinner.vue";

export default {
  components: {
    Toast,
    LoadingSpinner,
  },
  computed: {
    loading() {
      return this.$store.state.loading.length > 0;
    },
    noNavbar() {
      const pagesWithoutNavbar = ["Login", "UpdatePassword"];
      return pagesWithoutNavbar.includes(this.$route.name);
    },
  },
  mounted() {
    // Check for user and token in localstorage
    document.title = "Remote Sensor Monitor";
    const user = JSON.parse(localStorage.getItem("loggedUser"));
    const token = localStorage.getItem("idToken");
    if (user && token) {
      this.$store.dispatch("setLoggedUser", { user, token });
    }
  },
};
</script>

<style lang="scss">
@import "./components/Shared/global.scss";
.loading-spinner {
  position: absolute;
  top: 47%;
  left: 48.5%;
  width: 5em;
}
.loading-spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(122, 122, 122, 0.2);
  z-index: 8;
}
.toast-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}
select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 1em;
  padding-right: 1em;
}
</style>
